<template>
	<screen class="steps-view" size="large">
		<h1 slot="title">
			Welcome to RoundUp App! We need you to complete a few things...
		</h1>
		<collapsible
			:open="nextUp() === 1"
			size="large"
			:header_color="hasStripe && 'green'"
		>
			<h1 slot="title">Step 1: Set Up Payout</h1>
			<p v-if="!hasStripe" slot="message">Incomplete</p>
			<p v-else slot="message">Complete</p>
			<div class="row" slot="body">
				<img
					src="@/assets/rua_stripe.png"
					style="height: 175px; margin-right: 15px; flex: 1"
				/>
				<div style="flex: 2">
					<p>
						Stripe is our payment processor and the one used by
						companies like Venmo and Lyft. It takes less than 5
						minutes to get set up and there are some key benefits:
					</p>
					<li>
						Your organization’s name will appear on donors’ bank
						statements.
					</li>
					<li>
						Donations will automatically post to your organization’s
						bank account in a single transaction at the end of each
						month.
					</li>
					<p>
						To this end, we need you to create, or link, a Stripe
						account.
					</p>
					<ui-button @click="linkStripe">Connect Stripe</ui-button>
				</div>
			</div>
		</collapsible>
		<collapsible
			:open="nextUp() === 2"
			size="large"
			:header_color="hasBrand && 'green'"
		>
			<h1 slot="title">Step 2: Set Up Your Custom Registration Page</h1>
			<p v-if="!hasBrand" slot="message">Incomplete</p>
			<p v-else slot="message">Complete</p>
			<div class="row" slot="body">
				<img
					src="@/assets/hfb_brand.png"
					style="height: 155px; margin-right: 15px; flex: 1"
				/>
				<div style="flex: 3">
					<p>
						The best way to convert new donors is to use and post
						your custom registration link in all communication!
					</p>
					<li>Add your logo and cover photo</li>
					<li>Confirm your Call To Action and Impact Statement</li>
					<ui-button @click="goToBrand">View My Brand</ui-button>
				</div>
			</div>
		</collapsible>
		<!-- <collapsible :open="nextUp() === 3" size="large" :header_color="scheduledCall && 'green'">
            <h1 slot="title">Step 3: Schedule a Call with Your Growth Specialist</h1>
            <p v-if="!scheduledCall" slot="message">Incomplete</p>
            <p v-else slot="message">Complete</p>
            <div class="row" slot="body">
                <div :style="{marginRight: '15px', flex: 5, maxHeight: '650px'}" class="meetings-iframe-container" data-src="https://meetings.hubspot.com/jeni8/launching-with-roundup-app?embed=true"></div>
                <div style="flex: 3">
                    <p style="marginBottom: 0px">Schedule a call with us – there’s no cost and no catch.  We are here to help you be successful.  We have helped thousands of nonprofits grow with RoundUp App, so we can help you with any questions including:</p>
                    <li>Successfully promoting RoundUp App</li>
                    <li>Stripe Setup</li>
                    <li>Custom Registration Page Setup</li>
                    <li>Donation Distribution Questions</li>
                    <li>Marketing Materials</li>
                    <li>Technical Questions</li>
                </div>
            </div>
        </collapsible> -->
		<collapsible
			:open="nextUp() === 3"
			size="large"
			:header_color="launchDate && 'green'"
		>
			<h1 slot="title">
				Step 3: When Will You Begin To Promote RoundUp App?
			</h1>
			<p v-if="!launchDate" slot="message">Incomplete</p>
			<p v-else slot="message">Complete</p>
			<div class="row" slot="body">
				<img
					src="@/assets/calendarCard.png"
					style="height: 200px; margin-right: 15px"
				/>
				<div>
					<p style="margin-bottom: 0px">
						After working with thousands of nonprofits, we have
						learned that a planned marketing campaign is most
						effective.
					</p>
					<strong
						>So when are you planning to start your promotional
						campaign?</strong
					>
					<datepicker
						v-model="institution.launch_date"
						:inline="true"
						:use-utc="true"
					></datepicker>
					<ui-button :loading="isLoading" @click="setLaunchDate"
						>Set Launch Date</ui-button
					>
				</div>
			</div>
		</collapsible>
		<!-- <collapsible :open="nextUp() === 5" size="large" :header_color="onWebsite && 'green'">
            <h1 slot="title">Step 5: Add Your Custom Registration Link to Your Website</h1>
            <p v-if="!onWebsite" slot="message">Incomplete</p>
            <p v-else slot="message">Complete</p>
                <div class="row" slot="body">
                    <img src="@/assets/gpspca_banner.png" style="maxHeight: 200px; marginRight: 15px"/>
                <div>
                <p style="marginBottom: 0px">The best way to convert new donors is to use and post your custom registration link! Add it to your website's home page (<a href="institution.website_url" target="_blank">{{institution.website_url}}</a>)! Verify you have added the link to your webpage by clicking below. Just as a reminder, your custom registration link is:</p>
                    <div class="alert alert-boring">{{deeplink}}</div>
                    <a class="roundup-button append" :href="deeplink" target="_blank">View Page</a>
                    <br/>
                    <ui-button v-if="!found" @click="verifyLink" :loading="isLoading">Verify Custom Link</ui-button>
                    <h2 v-if="found">We found your link! Good Job!</h2>
                </div>
            </div>
        </collapsible> -->
	</screen>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapActions } from 'vuex'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import Collapsible from '../../ui/Collapsible'
import axios from 'axios'

export default {
	name: 'steps-view',
	components: { Screen, UiButton, UiLabel, Collapsible, Datepicker },
	data() {
		return {
			isLoading: false,
			found: false,
			institution: cloneDeep(this.$store.state.institution.item)
		}
	},
	computed: {
		...mapGetters([
			'institutionUuid',
			'onboardingStep',
			'hasBrand',
			'launchDate',
			'hasStripe',
			'institutionHasFinishedOnboarding'
		]),
		deeplink() {
			return 'https://roundup.app/p/' + this.institution.username
		}
	},
	methods: {
		...mapActions(['getInstitutionDetail', 'updateInstitution']),
		nextUp(override = null) {
			if (override) {
				return override
			}
			if (!this.hasStripe) {
				return 1
			} else if (!this.hasBrand) {
				return 2
			} else if (!this.launchDate) {
				return 3
			}
			return -1
		},
		linkStripe() {
			window.location.href =
				process.env.VUE_APP_stripeUrl + '&state=' + this.institutionUuid
		},
		goToBrand() {
			this.$router.push({ name: 'brand' })
		},
		// async verifyLink() {
		// 	this.isLoading = true

		// 	axios
		// 		.get(
		// 			'https://cors-anywhere.herokuapp.com/' +
		// 				this.institution.website_url,
		// 			{
		// 				headers: { 'Access-Control-Allow-Origin': '*' }
		// 			}
		// 		)
		// 		.then(data => {
		// 			const found =
		// 				data.data.includes(
		// 					'roundup.app/p/' + this.institution.username
		// 				) ||
		// 				data.data.includes(
		// 					'app.roundupapp.com/#/Register/' +
		// 						this.institutionUuid
		// 				)
		// 			if (found) {
		// 				this.institution.on_website = true

		// 				this.updateInstitution(this.institution)
		// 					.then(() => {
		// 						this.found = true
		// 						this.isLoading = false
		// 						this.$swal({
		// 							title: 'Awesome Job!',
		// 							text:
		// 								'We found the link on your home page!',
		// 							showCancelButton: false,
		// 							allowOutsideClick: true,
		// 							allowEscapeKey: false
		// 						}).then(() => {
		// 							window.location.reload()
		// 						})
		// 					})
		// 					.catch(err => console.log(err))
		// 			} else {
		// 				this.isLoading = false
		// 				alert(
		// 					"We couldnt find the link... Are you sure it's on your home page?"
		// 				)
		// 			}
		// 		})
		// 		.catch(err => {
		// 			console.log(err)
		// 			this.isLoading = false
		// 		})
		// },
		setLaunchDate() {
			this.isLoading = true
			this.institution.launch_date = moment(this.institution.launch_date)
				.tz('UTC')
				.format('YYYY-MM-DD')
			this.updateInstitution(this.institution)
				.then(() => {
					this.isLoading = false
					this.$swal({
						title: 'Success',
						text:
							'The start to your marketing campaign has been recorded. You can always come back and change it here.',
						showCancelButton: false,
						allowOutsideClick: true,
						allowEscapeKey: false
					}).then(() => {
						window.location.reload()
					})
				})
				.catch(() => {
					this.isLoading = false
				})
		}
	},
	mounted() {
		let hsScript = document.createElement('script')
		hsScript.setAttribute(
			'src',
			'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
		)
		document.head.appendChild(hsScript)
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.steps-view {
	li {
		margin-left: 50px;
	}
	.row {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}
</style>
