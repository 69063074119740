// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/Carrot.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".collapsible{background:#fff;-webkit-box-shadow:0 10px 20px rgba(0,0,0,.2);box-shadow:0 10px 20px rgba(0,0,0,.2);margin-top:20px;margin-bottom:20px;padding:20px;border-radius:14px;overflow:hidden;width:100%}.collapsible.small{max-width:420px}.collapsible.medium{max-width:600px}.collapsible.large{max-width:1280px}.collapsible.collapsed{padding:20px 20px 0 20px}.collapsible .collapsible-header{display:-webkit-box;display:-ms-flexbox;display:flex;position:relative;background:#f0f1f5;margin:-20px -20px 0 -20px;padding:15px 20px 15px 20px;border-bottom:1px solid #e3e8ef}.collapsible .collapsible-header .collapsible-title{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.collapsible .collapsible-header .collapsible-title h1{font-size:24px;font-weight:600;margin-top:5px;margin-bottom:0}.collapsible .collapsible-header .collapsible-title.collapsed{color:#71788c}.collapsible .collapsible-header .collapsible-buttons{-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0;-ms-flex-negative:0;flex-shrink:0}.collapsible .collapsible-header .collapsible-buttons button{margin-top:0}.collapsible .collapsible-header .caret{position:absolute;right:40px;top:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;height:25px;width:39px}.collapsible .collapsible-header .caret.collapsed{-webkit-transform:scale(.8) rotate(90deg);transform:scale(.8) rotate(90deg)}.collapsible .collapsible-header .message{position:absolute;right:100px;top:5px;color:#71788c}.collapsible .collapsible-header.header-background--green{background:#88ee9a}.collapsible .collapsible-body{margin-top:20px}.collapsible .collapsible-body.collapsed{margin-top:0;height:0}.collapsible .collapsible-footer{margin-top:20px;text-align:center}", ""]);
// Exports
module.exports = exports;
